<!--  -->
<template>
    <div class='page-subject-index'>
		<router-view
        :appStyle.sync="appStyle"
         />
    </div>
</template>

<script>
    import apiClass from '@/API/page/game'


    export default {
        name: '',
        props: {
            appStyle: Object
        },
        data() {
            return {
                api: new apiClass(),

            };
        },
        methods: {
            async gameListInit(){
                let res = await this.api.getAIList()
                // let res = await this.api.getLevelList()
                if(res) this.AIList = res
            },
        },
        created() {
        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
	.page-subject-index{
		position: relative;
		// height: 100%;
		// width: 100%;
	}
</style>